import React from "react";
import { Helmet } from "react-helmet";
// import { graphql, useStaticQuery } from "gatsby";

// const query = graphql`
//   {
//     site {
//       siteMetadata {
//         author
//         description
//         siteUrl
//         title
//         twitterUsername
//         image
//       }
//     }
//   }
// `;

const Seo = ({ data }) => {
  // // const { siteMetadata } = useStaticQuery(query);

  // const { site } = data;
  // const { siteMetadata } = site;

  // const { author, description, siteUrl, title, twitterUsername, image } =
  //   siteMetadata;

  return (
    <Helmet
      htmlAttributes={{ lang: "en" }}
      title={`Belle Carrie - Front-end developer | JAMstack`}
    >
      <meta
        name="description"
        content={`This is Belle Carrie's Portfolio Site`}
      />
      <meta name="image" content={`/twitter-img.png`} />
      {/* twitter cards */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content={`@BCarrie5`} />
      <meta
        name="twitter:title"
        content={`Belle Carrie - Front-end developer | JAMstack`}
      />
      <meta
        name="twitter:description"
        content={`Belle Carrie's Portfolio Site`}
      />
      <meta
        name="twitter:image"
        content={`${`https://bellecarriedev.com`}${`/twitter-img.png`}`}
      />
    </Helmet>
    // <Helmet htmlAttributes={{ lang: "en" }} title={`${title} | ${siteTitle}`}>
    //   <meta name="description" content={description || siteDesc} />
    //   <meta name="image" content={image} />
    //   {/* twitter cards */}
    //   <meta name="twitter:card" content="summary_large_image" />
    //   <meta name="twitter:creator" content={twitterUsername} />
    //   <meta name="twitter:title" content={siteTitle} />
    //   <meta name="twitter:description" content={siteDesc} />
    //   <meta name="twitter:image" content={`${siteUrl}${image}`} />
    // </Helmet>
  );
};

export default Seo;
